@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.headline-1 {
    @apply font-bold text-2xl md:text-4xl lg:text-6xl py-3 lg:py-10;
}
.headline-2 {
    @apply font-bold text-xl md:text-2xl lg:text-5xl py-2 lg:py-8;
}
.headline-4 {
    @apply font-bold text-sm md:text-lg lg:text-2xl;
}

body, html {
    @apply text-grey;  
    font-family: "Montserrat",sans-serif;
    scroll-behavior: smooth;
}
h1 {
    @apply headline-1;
}
.feature {
    @apply text-2xl md:text-5xl lg:text-8xl;
}
h2 {
    @apply headline-2;
}

h3 {
    @apply font-bold text-lg md:text-xl lg:text-4xl;
}

@layer utilities {
    .text-grey {
        color: #2C363E;
    }
    .border-primary {
        border: 1px solid #F6DB7A;
    }
    .bg-primary {
        background-color: #F6DB7A;
    }
    .border-secondary {
        border: 1px solid #2C363E;
    }
    .bg-secondary {
        color: white;
        background-color: #2C363E;
    }
}



/* a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 0px;
  left: 0%;
  background: black;
  transition: width .3s ease;
} */

/* a:hover:after{
  width: 100%;
  left: 0%;
  background: black;
} */




/* .hover {
  background-color: red;
  opacity: 0.5;
} */

/* .cursorinnerhover {
  width: 50px;
  height: 50px;
  opacity: .5;
} */